//Font
@font-face
    font-family: 'Founders Grotesk'
    font-weight: 700
    font-style: italic
    src: url("../fonts/founders-grotesk-bold-italic.woff2") format('woff2')
@font-face
    font-family: 'Founders Grotesk'
    font-weight: 700
    src: url("../fonts/founders-grotesk-bold.woff2") format('woff2')
@font-face
    font-family: 'Founders Grotesk'
    font-weight: 300
    font-style: italic
    src: url("../fonts/founders-grotesk-light-italic.woff2") format('woff2')
@font-face
    font-family: 'Founders Grotesk'
    font-weight: 300
    src: url("../fonts/founders-grotesk-light.woff2") format('woff2')
@font-face
    font-family: 'Founders Grotesk'
    font-weight: 500
    font-style: italic
    src: url("../fonts/founders-grotesk-medium-italic.woff2") format('woff2')
@font-face
    font-family: 'Founders Grotesk'
    font-weight: 500
    src: url("../fonts/founders-grotesk-medium.woff2") format('woff2')
@font-face
    font-family: 'Founders Grotesk'
    font-weight: 400
    font-style: italic
    src: url("../fonts/founders-grotesk-regular-italic.woff2") format('woff2')
@font-face
    font-family: 'Founders Grotesk'
    font-weight: 400
    src: url("../fonts/founders-grotesk-regular.woff2") format('woff2')
@font-face
    font-family: 'Founders Grotesk'
    font-weight: 600
    font-style: italic
    src: url("../fonts/founders-grotesk-semibold-italic.woff2") format('woff2')
@font-face
    font-family: 'Founders Grotesk'
    font-weight: 600
    src: url("../fonts/founders-grotesk-semibold.woff2") format('woff2')

// fonts
=founders-grotesk
    font-family: 'Founders Grotesk', sans-serif
