@import ../../styles/helpers

.theme
    display: block
    position: relative
    user-select: none 
    cursor: pointer
    -webkit-tap-highlight-color: rgba(0,0,0,0)
    font-size: 0
    &.wide
        +d
            .input:checked + .inner
                .box
                    &:first-child
                        display: flex
            .inner
                display: flex   
                padding: 4px 2px 
                border-radius: 20px
            .box
                display: flex
                height: 32px
                border-radius: 16px
                font-size: 15px
                svg
                    margin-right: 8px

.invisible
    .inner
        display: inline-block
        padding: 2px
        border-radius: 50%
    .box
        font-size: 0
        width: 40px
        height: 40px
        border-radius: 50%
        flex-basis: initial
        > svg
            margin-right: 0
        &:nth-child(2)
            display: none
    .input:checked + .inner
        .box
            &:first-child
                display: none
            &:nth-child(2)
                display: flex


.input
    position: absolute
    top: 0
    left: 0
    opacity: 0
    &:checked + .inner 
        background: $n8
        .box
            &:first-child
                background: none
                box-shadow: none
                color: $n4
                +d
                    display: none
                +m
                    display: flex
                svg
                    fill: $n4
                &:hover
                    color: $n1
                    svg
                        fill: $n1
            &:nth-child(2)
                box-shadow: 0px 4px 8px -4px rgba(0, 0, 0, 0.25), inset 0px -1px 1px rgba(0, 0, 0, 0.49), inset 0px 2px 1px rgba(255, 255, 255, 0.06)
                background: $n6
                color: $n1
                svg
                    fill: $n1
                +d
                    display: flex


.inner
    position: relative
    display: flex   
    padding: 4px 2px 
    border-radius: 20px
    background: $n2
    transition: background .2s
    +d
        display: block
        padding: 2px
        border-radius: 50%
    +m
        display: flex
        padding: 4px 2px
        border-radius: 20px

.box
    display: flex
    justify-content: center
    align-items: center
    flex: 0 0 calc(50% - 4px)
    height: 32px
    margin: 2px
    border-radius: 16px
    font-size: 15px
    transition: background .2s, box-shadow .2s, color .2s
    +d
        height: 40px
        border-radius: 50%
        font-size: 0
    +m
        height: 32px
        border-radius: 16px
        font-size: 15px
    svg
        margin-right: 8px
        transition: fill .2s
        +d
            margin-right: 0
        +m
            margin-right: 8px
    &:first-child
        background: $n1
        box-shadow: 0px 4px 8px -4px rgba(0, 0, 0, 0.25), inset 0px -1px 1px rgba(0, 0, 0, 0.04), inset 0px 2px 0px rgba(255, 255, 255, 0.25)
    &:nth-child(2)
        color: $n4
        +d
            display: none
        +m
            display: flex
        svg
            fill: $n4
    &:hover
        color: $n7
        svg
            fill: $n7


    