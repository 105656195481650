@import ../../styles/helpers

.modal
  display: flex
  position: fixed
  top: 0
  left: 0
  width: 100%
  height: 100%
  padding: 60px 10px
  background: $n7
  opacity: 0
  animation: showModal .4s forwards
  overflow-x: hidden
  z-index: 999
  +t
    padding: 112px 24px 56px
  +m
    padding: 148px 16px 32px


@keyframes showModal
  0%
    opacity: 0
  100%
    opacity: 1

.outer
  max-width: 1124px
  width: 100%
  margin: auto

.video
  video
    width: 100%
    border-radius: 12px

.wrapper
  transition: transform 0.3s ease 
  overflow: hidden
  margin: auto
  max-width: 850px
  padding: 0 56px
  +m
    padding: 0

.preview
  display: flex
  align-items: center
  flex-direction: column
  height: 100%
  img
    width: 100%
    border-radius: 16px
    &:hover
      cursor: zoom-in
  

.figcaption
  margin-top: 16px
  text-align: center
  +caption1
  color: $n4

.zoomed
  transform: scale(1.1)
  cursor: zoom-out  